import { Injectable } from '@angular/core';
import { catchError, map, tap, throwError, type Observable } from 'rxjs';
import { ApiService } from '@services/core-services/api.service';
import { Query } from '@services/core-services/models';
import { NotifyService } from './notify.service';

@Injectable({ providedIn: 'root' })
export class GlobalSearchService {
  constructor(
    private readonly api: ApiService,
    private readonly notify: NotifyService
  ) {}

  getSearchData(query: Query) {
    return this.api.get<any>(`store/search`, query).pipe(
      map((response: any) => {
        return response.content;
      })
    );
  }

  refreshProductIndex(): Observable<any> {
    return this.api.get<any>(`catalog/refresh-index`).pipe(
      tap(() => this.notify.success('Order index refreshed successfully')),
      catchError(err => {
        this.notify.error('Failed to refresh order index: ' + err.message);
        return throwError(() => err);
      })
    );
  }

  refreshOrderIndex(): Observable<any> {
    return this.api.get<any>(`orders/refresh-index`).pipe(
      tap(() => this.notify.success('Product index refreshed successfully')),
      catchError(err => {
        this.notify.error('Failed to refresh product index: ' + err.message);
        return throwError(() => err);
      })
    );
  }

  refreshCustomerIndex(): Observable<any> {
    return this.api.get<any>(`customers/refresh-index`).pipe(
      tap(() => this.notify.success('Customer index refreshed successfully')),
      catchError(err => {
        this.notify.error('Failed to refresh customer index: ' + err.message);
        return throwError(() => err);
      })
    );
  }
}
